<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card class="px-4">
      <v-data-table
        :headers="headers"
        :items="tiket"
        :items-per-page="25"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="mt-4 px-8 pt-4 pb-10"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="#36AC87"
                single-line
                hide-details
                clearable
                outlined
                dense
                @keyup.enter.native="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div
            class="action-container"
            style="
                  width: 36px;
                  height: 29px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #F2F2F2;
                  box-sizing: border-box;
                  border-radius: 4px;
                  cursor: pointer;
                "
            @click="toDetail(item)"
          >
            <v-icon class="action-icon">
              mdi-eye
            </v-icon>
          </div>
        </template>

        <!-- <template v-slot:item.status_string="{ item }">
          <v-chip :color="'#' + item.status_color">
            <span style="color: #FFFFFF">
              {{ item.status_string }}
            </span>
          </v-chip>
        </template> -->

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="#2E976C"
              :total-visible="5"
              @input="onChangePage"
            />
            <span style="font-size: 12px; font-style: italic;">
              Page {{ page }} of {{ pageCount || 1 }}
            </span>
          </div>
          <v-row class="mt-n16">
            <v-col class="text-right pr-8">
              <span style="font-size: 12px; font-style: italic;">
                {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
              </span>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOG -->
    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <template v-if="selectedTiket !== null">
        <v-card class="px-4 py-4">
          <v-row style="background: #36AC87">
            <v-col class="txt16-white-bold">
              {{ selectedTiket.title || '-' }}
            </v-col>

            <v-col class="text-right font-common-white">
              <v-icon
                style="
                cursor: pointer;
                color: #FFFFFF;"
                @click="dialog = false"
              >
                fas fa-times
              </v-icon>
            </v-col>
          </v-row>

          <!-- <v-row
            style="
            border-top: 1px solid white;
          background: #7A1878;
          "
          >
            <v-col class="font-common-white">
              {{ selectedTiket.submit_by_user_name }}
            </v-col>

            <v-col class="text-right font-common-white">
              {{ selectedTiket.created_time }}
            </v-col>
          </v-row> -->

          <!-- <v-row>
            <v-col class="px-6">
              <div
                class="mt-5 mb-n5 px-3 py-3"
                style="
                  border: 1px solid #F2F2F2;
                  box-sizing: border-box;
                  border-radius: 4px;
                  background: #F2F2F2;
                  color: #B2B2B2;
                "
              >
                <div
                  class="font-common-bold mb-1"
                  style="color: #36AC87"
                >
                  {{ selectedTiket.submit_by_user_name || '-' }}
                </div>

                <div class="font-common-black">
                  {{ selectedTiket.title_description || '-' }}
                </div>

                <div
                  class="d-flex"
                  style="justify-content: flex-end"
                >
                  {{ selectedTiket.created_time || '-' }}
                </div>
              </div>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col class="px-6">
              <div
                v-for="(v, i) in selectedTiket.list_chat"
                :key="'ticket-' + i"
                class="mt-5 px-3 py-3"
                style="
                  border: 1px solid #F2F2F2;
                  box-sizing: border-box;
                  border-radius: 4px;
                "
                :style="v.bubble_chat_pos === 'L' ? 'background: #F2F2F2; color: #B2B2B2;' : 'background: #36AC87; color: #FFFFFF;' "
              >
                <div
                  class="font-common-bold"
                  :style="v.bubble_chat_pos === 'L' ? 'color: #36AC87;' : 'color: #FFC709;' "
                >
                  {{ v.name || '-' }}
                </div>

                <div
                  class="font-common"
                  :style="v.bubble_chat_pos === 'L' ? 'color: black;' : 'color: white;' "
                >
                  {{ v.content || '-' }}
                </div>

                <!-- <template v-if="v.documents.list_file > 0">
                  <div
                    class="mt-5 mb-2 font-common"
                    :style="v.bubble_chat_pos === 'L' ? 'color: black;' : 'color: white;' "
                  >
                    Lampiran :
                  </div>

                  <div
                    v-for="(x, y) in v.documents"
                    :key="'thread-doc-' + y"
                  >
                    <span :style="v.bubble_chat_pos === 'L' ? 'color: black;' : 'color: white;' ">
                      {{ x.name }}
                    </span>

                    <span
                      style="cursor: pointer"
                      @click="downloadLampiran(x.path)"
                    >
                      <v-icon color="white">
                        mdi-arrow-down-bold-circle
                      </v-icon>
                    </span>
                  </div>
                </template> -->

                <div
                  class="d-flex"
                  style="justify-content: flex-end"
                >
                  {{ v.date || '-' }}
                </div>
              </div>

              <!-- <template v-if="ticketMod"> -->
              <template v-if="selectedTiket.show_button">
                <hr
                  style="
                margin-top: 180px;
                border: 1px solid #E6E6E6;
              "
                >

                <div class="mt-6">
                  <v-textarea
                    v-model="isiPesan"
                    class="mt-3"
                    color="#36AC87"
                    outlined
                    no-resize
                    placeholder="Ketik Pesan Anda di sini"
                  />
                </div>

                <!-- <div class="font-common">
                  Upload File
                </div> -->

                <div
                  class="d-flex mb-6"
                  style="justify-content: space-between"
                >
                  <template v-if="lampiran === null">
                    <upload-button
                      id="lampiran"
                      class="px-0"
                      :is-loading="isLoading"
                      :upload-file="uploadedFile"
                      @uploadButtonClick="uploadFile"
                    />
                    <v-file-input
                      id="upload-file"
                      accept="image/*"
                      style="display: none"
                      @change="upload"
                    />
                  </template>

                  <template v-else>
                    <span class="font-common-bold mt-2">
                      {{ lampiran.file_name || '-' }}
                    </span>
                  </template>

                  <!-- <div class="d-flex align-center">
                    <v-btn
                      outlined
                      style="border: 1px solid #E6E6E6"
                      @click="upload"
                    >
                      <span
                        class="font-common-bold text-capitalize"
                        style="color: #36AC87"
                      >
                        Unggah
                      </span>
                    </v-btn>
                  </div> -->

                  <v-file-input
                    id="upload-lampiran"
                    style="display: none"
                    @change="uploadLampiran"
                  />
                <!-- {{ lampiran }} -->
                </div>

                <hr style="border: 1px solid #E6E6E6">

                <template>
                  <div
                    class="d-flex pt-2 mt-4 mb-2"
                  >
                    <v-btn
                      block
                      class="mr-0"
                      style="
                      background: #36AC87;
                      border: none;
                      outline: none;
                    "
                      @click="showCnf(1)"
                    >
                      <span
                        class="font-common-white-bold text-capitalize"
                        style="color: #FFFFFF"
                      >
                        Kirim Pesan
                      </span>
                    </v-btn>
                  </div>

                  <div class="mb-3">
                    <v-btn
                      class="elevation-0"
                      block
                      style="background: #CCCCCC; color: white"
                      @click="showCnf(2)"
                    >
                      <span class="font-common-bold text-capitalize">
                        Tutup Tiket
                      </span>
                    </v-btn>
                  </div>
                </template>
              </template>
              <!-- <template v-else>
                <v-row>
                  <v-col class="font-common text-center">
                    Tidak ada akses
                  </v-col>
                </v-row>
              </template> -->
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>

    <modal-konfirmasi
      :show="modal"
      @submit="confirm"
    />
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  import UploadButton from '../komponen/UploadButton.vue'
  import ModalKonfirmasi from '../komponen/modal/ModalKonfirmasi.vue'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {

    components: {
      UploadButton,
      ModalKonfirmasi,
    },

    data: () => ({
      dialog: false,
      search: '',
      headers: [
        { text: 'Judul Tiket', align: 'start', value: 'title' },
        { text: 'Tanggal', value: 'date', sortable: false },
        { text: 'Pengirim', value: 'sender', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      tiket: [],
      isAllowDetail: false,
      isAllowVerification: false,
      selectedTiket: null,
      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,
      lampiran: null,
      isiPesan: '',
      isLoading: false,
      uploadedFile: null,
      fileHash: null,
      konfirmasiDialog: false,
      modal: false,
      submitKey: 1,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
    },

    methods: {
      uploadFile () {
        document.getElementById('upload-file').click()
      },

      showCnf (p) {
        this.submitKey = p
        this.modal = true
      },

      confirm (p) {
        // console.log(p)
        if (p === 0) {
          this.modal = false
        } else {
          this.modal = false
          this.submitKey === 1 ? this.reply() : this.closeTicket()
        }
      },

      initialize (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }
        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v1/admin/ticket', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.tiket = res.data.data.ticket.list
            this.pageCount = res.data.data.ticket.pagination.last_page
            this.itemCount = res.data.data.ticket.pagination.count
            this.itemTotal = res.data.data.ticket.pagination.total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      toDetail (p) {
        const requestBody = {
          ticket_hash: p.ticket_hash,
        }

        axios.post('/v1/admin/ticket/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            // console.log(res)
            this.selectedTiket = res.data.data
            // this.selectedTiket.thread = res.data.data.
            this.lampiran = null
            this.dialog = true
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      uploadLampiran (e) {
        // console.log(e)
        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('image', e)
        // requestBody.append('dest', 'orderDoc')

        axios.post(baseCDNurl + '/v1/g/ticket/uploadFile', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data)
            this.lampiran = res.data.data
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      reply () {
        const files = []
        if (this.fileHash !== null) {
          files.push(this.fileHash)
        }

        const requestBody = {
          ticket_hash: this.selectedTiket.ticket_hash,
          list_docs: files,
          content: this.isiPesan,
        }

        axios.post('/v1/admin/ticket/reply', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil')
            this.lampiran = null
            this.isiPesan = ''
            this.dialog = false
          }
        })
          .catch((e) => {
            this.dialog = false
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      closeTicket () {
        const requestBody = {
          ticket_hash: this.selectedTiket.ticket_hash,
        }

        axios.post('/v1/admin/ticket/close', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil')
            this.dialog = false
            this.initialize()
          }
        })
          .catch((e) => {
            this.dialog = false
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      upload (e) {
        this.isLoading = true
        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('file', e)
        requestBody.append('dest', 'member_photo')

        axios.post(baseCDNurl + '/v1/upload', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            this.isLoading = false
            this.uploadedFile = res.data.data
            this.fileHash = res.data.data.tmp_file_hash
          }
        })
          .catch((e) => {
            this.isLoading = false
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      downloadLampiran (url) {
        window.open(url, '_blank')
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt16-white-bold {
  @extend .p-1;
  color: white;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2B7DEC;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2B7DEC;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
